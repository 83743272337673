import { useContext, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from 'react-router-dom';
import Bell from '../../dustControl/assets/icons/bell.png';
import DropdownField from '../../dustControl/components/FormDropdown';
import { links, sections } from '../../dustControl/utils/constants';
import { getFirstLetter, isObjectEmpty } from '../../dustControl/utils/helper';
import ProfileModal from '../../pages/profile/ProfileModal';
import { Logo } from '../assets/logo';
import { UserContext } from '../service/userContext';
import back from '../assets/back.svg';

const Header = ({
  selectedSubText,
  showLogo,
  title,
  showDropDown,
  code,
  showBack = false,
  onBack,
}) => {
  const navigate = useNavigate();
  const { userInfo } = useContext(UserContext);
  const [visible, setVisible] = useState(false);
  const [values, setValues] = useState({
    Department: code,
  });

  const getAllOptions = () => {
    if (!userInfo.departments) return [];
    return userInfo.departments.map((code) => ({
      name: sections[code],
      value: code,
    }));
  };
  const handleChange = (fieldName, value, multiselect) => {
    setValues({ Department: value });
    return navigate(links[value]);
  };
  return (
    <>
      <Navbar className="p-0">
        <Container className="align-items-baseline" fluid>
          <Navbar.Toggle />

          <div className={`dust-header w-100`}>
            <Navbar.Collapse className={`align-items-start navbar-collapse`}>
              {showLogo ? (
                <Navbar.Brand>
                  <Logo textColor={'black'} />
                </Navbar.Brand>
              ) : null}
              <Navbar.Text
                className={`navbar-text ps-${
                  selectedSubText ? '3' : '0'
                } pt-0 text-dark d-flex`}
              >
                {showBack && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '0px 5px',
                      cursor: 'pointer',
                    }}
                    onClick={() => onBack()}
                  >
                    <img src={back} height={18} alt="back" />
                  </div>
                )}
                {selectedSubText ? selectedSubText : title}
              </Navbar.Text>

              <div className="d-flex gap-2 justify-content-end dropdown-icon-container">
                {showDropDown ? (
                  <div className="header-dropdown">
                    <DropdownField
                      selectedOptions={values.Department}
                      allOptions={getAllOptions()}
                      multiselect={false}
                      handleChange={handleChange}
                    />
                  </div>
                ) : null}

                <div className="avatar-circle small-avatar">
                  <img src={Bell} alt="Notification" className="text-white" />
                </div>
                <div
                  className="avatar-circle small-avatar color-white"
                  onClick={() => setVisible(!visible)}
                >
                  {!isObjectEmpty(userInfo)
                    ? `${getFirstLetter(userInfo.first_name)}${getFirstLetter(
                        userInfo.last_name,
                      )}`
                    : null}
                </div>
              </div>
            </Navbar.Collapse>
          </div>
        </Container>
      </Navbar>
      {visible && (
        <ProfileModal setVisible={setVisible} className="profilemodal" />
      )}
    </>
  );
};

export default Header;
